
<div>

  <form id="frm1" name="frm1" action="" method="post" novalidate="novalidate">
  <input type="hidden" id="schOrdNo" name="schOrdNo" value="M000067644">

  <!-------------------------- invoice ---------------------->
  <div class="wrap">
        <div class="report_title">
            <h2 class="title">Invoice</h2>
            <div class="row">
                <div class="col">
                    <h1 class="logo"><img class="img-dots" src="./assets/img/cooperation_img2.jpg"></h1>
                    <p>{{ company.comName }}</p>
                    <p>14630 Industry Cir</p>
                    <p>La Mirada, CA 90638</p>
                    <p>{{ company.tel }}</p>
                    <p>{{ company.eMail }}</p>
                </div>
                <div class="col align-self-end">
                    <p>Order <span id="orderNo">#{{ orderInfo.ordNo }}</span></p>
                    <br />
                    <p>Date <span>{{ (orderInfo.ordDate2 + '' | date : 'MMM. dd, y' )  == null  ? formatOrderDate(orderInfo.ordDate) : (orderInfo.ordDate2 + '' | date : 'MMM. dd, y' ) }}</span></p>
                </div>
            </div>
        </div>
        <div class="report_user row">
            <div class="col d-flex">
                <dl class="col-6">
                  <dt>BILL TO</dt>
                  <dd><span id="userName">{{ orderInfo.bName }}</span></dd>
                  <dd><span>{{orderInfo.bAddr1}}</span><span *ngIf="orderInfo.bAddr2 != null">{{orderInfo.bAddr2}}</span></dd>
                  <dd><span>{{(orderInfo.bCity != null? orderInfo.bCity:'') + ', ' + (orderInfo.bState !=null ? orderInfo.bState:'') + ' ' + (orderInfo.bPost != null ? orderInfo.bPost:'')}}</span></dd>
                  <dd><span id="mobile">{{ orderInfo.bMobile}}</span></dd>
                </dl>
                <dl class="col-6">
                    <dt>SHIP TO</dt>
                    <dd *ngIf="orderInfo.compName != null && orderInfo.compName != ''"><span>{{ orderInfo.compName }}</span></dd>
                    <dd><span id="userName">{{ orderInfo.rName }}</span></dd>
                    <dd><span>{{orderInfo.rAddr1}}</span><span *ngIf="orderInfo.rAddr2 != null">{{orderInfo.rAddr2}}</span></dd>
                    <dd><span>{{(orderInfo.rCity!=null?orderInfo.rCity:'') + ', ' + (orderInfo.rState!=null?orderInfo.rState:'') + ' ' + (orderInfo.rPost!=null?orderInfo.rPost:'')}}</span></dd>
                    <dd><span id="mobile">{{ orderInfo.rMobile }}</span></dd>
                </dl>
            </div>
            <!-- <div class="col">
                <dl>
                    <dt>Shipping Method</dt>
                    <ng-container *ngIf="orderInfo.shippPlan == 'UPS' || orderInfo.deliAmt == 15">
                        <dd>&lt;UPS Ground&gt;</dd>
                    </ng-container>
                    <ng-container *ngIf="orderInfo.shippPlan == 'UPS 3DAY' || orderInfo.deliAmt == 26">
                        <dd>&lt;UPS 3Day&gt;</dd>
                    </ng-container>
                </dl>
            </div> -->
        </div>
        <div class="report_tb">
            <table>
                <colgroup>
                    <col width="10%">
                    <col width="*">
                    <col width="10%">
                    <col width="15%">
                    <col width="15%">
                </colgroup>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Item Ordered</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderProducts; index as i">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.pdtName }}</td>
                        <td>{{ item.qty }}</td>
                        <td>{{ +item.price | setComma: 'price' }}</td>
                        <td>{{ +item.price * +item.qty | setComma: 'price' }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="2">Subtotal</th>
                        <th style="text-align: center;">{{ sumPdtQty }}</th>
                        <th></th>
                        <th>{{ +orderInfo.ordPrice | setComma: 'price' }}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <p style="margin-top: 25px; font-weight: 600;">Remarks / Payment Instructions:</p>
        <p style="float: left; width: 300px;">{{orderInfo.deliKind=='TAKE'?'Pick Up': orderInfo.rMemo}}</p>
        <div class="report_info">
            <table>
                <colgroup>
                    <col width="50%">
                    <col width="50%">
                </colgroup>
                <tr>
                    <th>Shipping</th>
                    <td>{{ orderInfo.deliAmt + '' | setComma: 'price' }}</td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>{{ orderInfo.ordVAT + '' | setComma: 'price' }}</td>
                </tr>
                <tr>
                    <th>Total Paid</th>
                    <td>{{  +orderInfo.ordAmt + +orderInfo.deliAmt  | setComma: 'price' }}</td>
                </tr>
            </table>
        </div>
        <div class="report_footer">
            <p>Terms & Instructions:<br />We offer free returns within 30 days of purchase. You can return your product for store credit, a different product, or a refund to the original payment method. Please contact us for details.</p>
        </div>
    </div>

  </form>
</div>
