export class CommonConstant {
    static YES = 'Y';
    static NO = 'N';
    static SLASH = "/";

    static PV1 = "pv1";
    static PV2 = "pv2";
    static PV3 = "pv3";

    static DEFAULT_IMAGE = "./assets/img/test.jpg";

    static TYPE_CART_NORMAL = "NORMAL";
    static TYPE_CART_ADO = "ADO";

    static CART_TYPE = "cart_type";
    static GUEST_CART = "guest_cart";
    static GUEST_CART_ITEM = "guest_cart_item";
    static TMP_USER = "tmp_user";
    static MEMBER = "member";
    static PREVIOUS_URL = "previous_url"

    static PRODUCT_RECENT = "pdtRecent";
    static EMPTY_MAX_ROW = 7;
    static BLANK = "";

    static readonly URL_ALLOW_PAGE_HEADING = new Map([
      ["/my-office/wishlist", "/my-office/wishlist"],
      ["/order-search-not-login", "/order-search-not-login"],
      ["/shopping-mall/event", "/shopping-mall/event"]
    ]);
}
